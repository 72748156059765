<template>
  <div>
    <base-navigation></base-navigation>
    <base-card>
   
    <form @submit.prevent="previewData">
  <div class="form-group">
    <div class="row">
      <div class="col">
        <div>
         <label for="name">Base currency:</label>  
          <select v-model="formData.base_currency" id="status" required>
            <option disabled value=""> Select</option>
            <option>USDT</option>
            <option>USD</option>

          </select>        
        </div> 
        <div>
          <label for="name">Counter currency:</label>
          <select v-model="formData.counter_currency" id="status" required>
            <option disabled value=""> Select one</option>
            <option>KES</option>
            <option>TZS</option>
            <option>ETB</option>
            <option>AOA</option>
            <option>ZMW</option>
            <option>EGP</option>
            <option>RWF</option>
            <option>MWK</option>

          </select>        
        </div> 

        <div>
          <label for="name">Select client:</label>

          <select  v-model="formData.client">
            <option disabled value="">
              Select client
            </option>
             <option   v-for="client in clients" :key="client.id">
        {{client.Business_name }}
    </option>
          </select>
      
         </div>
         <div>
          <label for="name">Select liquidity provider:</label>
          <select v-model="formData.liquidity_provider" id="liquidity" required>
            <option disabled value="">Please select one</option>
            <option   v-for="client in clients" :key="client.id">
        {{client.Business_name }}
    </option>          </select>       
         </div>

         <div>
          <label for="name">Total USDT(Optional)</label>
          <input type="number" v-model="formData.base_volume" id="base_volume"  />
        </div>

    
        
       

      </div>
      <div class="col">
        <div>
          <label for="name">Client Rate:</label>
          <input type="float" v-model="formData.c_rate" id="c_rate" required />
        </div>

        <div>
          <label for="name">Liquidity Rate:</label>
          <input type="float" v-model="formData.l_rate" id="l_rate" required />
        </div>

       
        
       
        <div>
          <label for="name">Volume in local currency</label>
          <input type="float" v-model="formData.local_volume" id="local_volume"  />
        </div>
        <div>
          <label for="name">Notes:</label>
          <input type="text" v-model="formData.notes" id="name"  />
        </div>
        <div>
          
          <label for="registrationDate">Trade Date:</label>
          <input type="date" v-model="formData.trade_date" id="registrationDate"  />
        </div>

</div>
<div class="col" v-if="showPreview">

    <small>
     <p class="text-primary">Trade details</p>



      <p><strong>client:</strong> {{ formData.client }}</p>
      <p><strong>Trade:</strong> {{ formData.base_currency }}/{{ formData.counter_currency  }}</p>
      <p><strong>liquidity provider:</strong> {{ formData.liquidity_provider }}</p>
      <p><strong>client Rate:</strong> {{ formData.c_rate }}</p>
      <p><strong>liquidity Rate:</strong> {{ formData.l_rate }}</p>
      
      <p><strong>Total local Volume:</strong> {{ this.computedLocalVolume }}</p>
      <p><strong>Total USDT In:</strong> {{ this.computedUsdIn }}</p>
       <p><strong>Total USDT Out:</strong> {{ this.computedUsdOut }}</p>
        <p><strong>Profit in USD:</strong> {{ this.computedProfit }}</p>



    </small>

    </div>

    </div>
   
        
  </div>

  <div class="row" v-if="showPreview">

    
    <div class="col">
        <button class="btn btn-primary" @click="confirmSubmit">Confirm</button>


    </div>
    <div class="col">
        <button class="btn btn-secondary" @click="editData">Edit</button>


    </div>

    

  </div>
  <div v-else>

    <button type="submit" class="btn btn-primary">Preview</button>




  </div>
  
 
 
</form>


</base-card>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      
        formData: {
        
        trade_date: today,
        client: '',
        liquidity_provider:'',
        c_rate:null,
        l_rate:null,
        local_volume:null,
        base_volume:null,
        base_currency:'',
        counter_currency:'',
        profit:'',
        notes:''
      },
      showPreview: false
    };
  },
  computed: {
        clients() {
            return  this.$store.getters.clients

            
        }, 
        computedBaseVolume() 
        {
      if (this.formData.base_volume === null && this.formData.local_volume !== null && this.formData.c_rate !== null) {
        return (this.formData.local_volume / this.formData.c_rate).toFixed(2);
      }
      return (this.formData.base_volume);
    },
    computedLocalVolume() {
      if (this.formData.local_volume === null && this.formData.base_volume !== null && this.formData.l_rate !== null) {
        return (this.formData.base_volume * this.formData.l_rate).toFixed(2);
      }
      return (this.formData.local_volume);
    },
    computedUsdIn(){
      return (this.computedLocalVolume/this.formData.l_rate).toFixed(2);
    },
     computedUsdOut(){
      return (this.computedLocalVolume/this.formData.c_rate).toFixed(2);
    },
    computedProfit(){
      return (this.computedUsdIn - this.computedUsdOut).toFixed(2);
    }

    },
    created() {
        this.$store.dispatch('LoadClients');  
    },
    
  methods: {
    previewData() {
      this.showPreview = true;
    },
    async confirmSubmit() {
      try {
        const dataToSubmit = {
          ...this.formData,
          base_volume: this.computedBaseVolume,
          local_volume: this.computedLocalVolume,
          profit: this.computedProfit
        };
        const response = await axios.post('https://api.roberms.com/durra/new/trade', dataToSubmit);
        console.log("Data submitted successfully:", response.data);
        // alert("Data submitted successfully!");
        this.resetForm();
      } catch (error) {
        console.error("Error submitting data:", error);
        // alert("Failed to submit data.");
      }
      await this.$router.replace("/clients");
    },
    async newTrade() {
      const formIsValid =
        this.formData.c_rate;
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/durra/new/trade",
          this.formData, this.computedBaseVolume, this.computedLocalVolume, this.computedUsdIn, this.computedUsdOut, this.computedProfit
        );
        console.log(response);

        await this.$router.replace("/clients");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.c_rate) {
          this.errors.push("Enter Business Name ");
        }
        

        console.log("form is invalid");
      }
    },
    // confirmSubmit() {
    //   console.log("Submitting data to database:", this.formData);
    //   alert("Data submitted successfully!");
    //   this.resetForm();
    // },
    editData() {
           this.formData.base_volume = null;
            this.formData.local_volume = null;


      this.showPreview = false;
    },
    resetForm() {
      this.formData = {
        trade_date: '',
          client: '',
          liquidity_provider:'',
          c_rate:null,
          l_rate:null,
          local_volume:null,
          base_volume:null,
          base_currency:'',
          counter_currency:'',
          notes:''
      };
      this.showPreview = false;
    }
  }
};
</script>

<style scoped>
  
  .client-registration-form {
    max-width: 400px;
    margin: auto;
  }
  form div {
    margin-bottom: 0.3rem;
  }
  label {
    display: block;
    margin-bottom: 0.3rem;
  }
  input, select, button {
    width: 100%;
    padding: 0.3rem;
  }
  </style>