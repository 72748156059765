<template>
    <div>
    <main-navigation></main-navigation>
    <header id="header" class="">
      <div class="main-content">
        <carousel-section></carousel-section>
          
      </div> 
    </header> 
    <div id="services" class="cards-1 bg-gray">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <h2>Explore our services</h2>
              </div> 
          </div>
          <div class="row">
              <div class="col-lg-12">
                  
                
                  <div class="card">
                      <div class="">
                          <span class="fas fa-thin fa-wallet"></span>
                      </div>
                      <div class="card-body">
                          <h5 class="card-title">Currency Trading</h5>
                          <p>Get in touch and trade over 100 pairs and exchange your crypto to Fiat currency</p>
                          <a class="read-more no-line" href="#">Learn more </a>
                      </div>
                  </div>
              
                  <div class="card">
                      <div class="">
                          <span class="far fa-clipboard"></span>
                      </div>
                      <div class="card-body">
                          <h5 class="card-title">International remitance</h5>
                          <p>Send and receive money from all over the world with ot instance international remitance services</p>
                          <a class="read-more no-line" href="#">Learn more <span class="fas fa-long-arrow-alt-right"></span></a>
                      </div>
                  </div>
                
                  <div class="card">
                     <div class="">
                         <span class="fas fa-dollar-sign"></span>
                      </div>
                      <div class="card-body">
                          <h5 class="card-title">Crypto conversions</h5>
                          <p>You have crypto ad want to convert to your local currency or want to buy crypto, we are here to serve you</p>
                          <a class="read-more no-line" href="#">Learn more <span class="fas fa-long-arrow-alt-right"></span></a>
                      </div>
                  </div>
                  
              </div>
          </div>
      </div>
    </div> 
    <div id="details" class="basic-1">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-xl-7">
                  <div class="image-container">
                      <img class="img-fluid" src="assets/images/details-1.png" alt="alternative" />
                  </div> 
              </div> 
              <div class="col-lg-6 col-xl-5">
                  <div class="text-container">
                      <div class="section-title">WHAT WE DO</div>
                      <h2>We help you trade and make payments for your clients</h2>
                      <p>For importers, worry not about how money will get to your prefered destination. We help you pay with your local currency</p>
                      <a class="btn-solid-reg" href="#contact">check rates</a>
                  </div> 
              </div> 
          </div>
      </div> 
    </div>
    <div id="partiners">

     
        <!-- Our partiners -->

        <partiner-section></partiner-section>
    </div>
    <div class="basic-2">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-xl-5">
                  <div class="text-container">
                      <div class="section-title">ABOUT US</div>
                      <h2>We have 5 years experience in trading</h2>
                      <p>With customers across the continent, Asia, Europe and America, we have build trust across the borders to help in supporting FX transactions to our clients</p>
                      <a class="btn-outline-reg" href="#">Details</a>
                  </div> 
              </div> 
              <div class="col-lg-6 col-xl-7">
                  <div class="image-container">
                      <img class="img-fluid" src="assets/images/details-2.png" alt="alternative" />
                  </div> 
              </div> 
          </div>
      </div> 
    </div> 
   
    
    <div id="leadership" class="container">
 
    
    <br>
    <br>
    <br>
    
    <br>
         <h1>Our Leadership</h1>
         <div class="grid row">
             <div class="leader-card col">
                 <img src="../../assets/obaigwa.jpg" alt="Leader 1" class="rounded-circle mt-4">
                 <h6>Joshua Obaigwa</h6>
                 <p>CEO and Founder</p>
                 <a target="_blank" href="https://www.linkedin.com/in/joshuaobaigwa/"><svg width="20" height="20" fill="currentColor" class="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path></svg></a>
                 <!-- <p> profile</p> -->
             </div>
             <div class="leader-card col">
                 <img src="../../assets/ronald.jpg" alt="Leader 3" class="rounded-circle mt-4">
                 <h6>Ronald Mosomi</h6>
                 <p>Chief Technology Officer</p>
                 <a target="_blank" href="https://www.linkedin.com/in/ronaldmosomi/"><svg width="20" height="20" fill="currentColor" class="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path></svg></a>
             </div>
             <div class="leader-card col">
                 <img src="../../assets/diana.jpg" alt="Leader 2" class="rounded-circle mt-4">
                 <h6>Diana Bitange</h6>
                 <p>Director, Accounts management</p>
                 <a target="_blank" href="https://www.linkedin.com/in/diana-bitange/"><svg width="20" height="20" fill="currentColor" class="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path></svg></a>
             </div>
            
             <div class="leader-card col">
                 <img src="../../assets/ogoti.jpg" alt="Leader 4" class="rounded-circle mt-4">
                 <h6>Dr. Vincent Ogoti</h6>
                 <span class="text-gray-400 dark:text-gray-300 text-base">Director strategy   &amp; communication</span>
                 <p></p>
                 <a target="_blank" href="https://www.linkedin.com/in/vincentogoti/"><svg width="20" height="20" fill="currentColor" class="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path></svg></a>
             </div>
         </div>
     </div>
    <div class="cards-2 bg-gray">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <h2 class="h2-heading">Customer testimonials</h2>
              </div> 
          </div>
          <div class="row">
              <div class="col-lg-12">
                  
                  <div class="card">
                      <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                      <div class="card-body">
                          <p class="testimonial-text">I have transacted with Durrafx and can attest they offer the best rates when in comes to forex</p>
                          <div class="testimonial-author">Ngozi</div>
                          <div class="occupation">Trader, Yellow card</div>
                      </div>
                      <div class="gradient-floor red-to-blue"></div>
                  </div>
                
                  
                  <div class="card">
                      <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                      <div class="card-body">
                          <p class="testimonial-text">I have transacted with Durrafx and can attest they offer the best rates when in comes to forex</p>
                          <div class="testimonial-author">Ngozi</div>
                          <div class="occupation">Trader, Yellow card</div>
                      </div>
                      <div class="gradient-floor blue-to-purple"></div>
                  </div>
                
                  
                  <div class="card">
                      <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                      <div class="card-body">
                          <p class="testimonial-text">I have transacted with Durrafx and can attest they offer the best rates when in comes to forex</p>
                          <div class="testimonial-author">Ngozi</div>
                          <div class="occupation">Trader, Yellow card</div>
                      </div>
                      <div class="gradient-floor purple-to-green"></div>
                  </div>
                
                  
                  
                  <div class="card">
                      <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                      <div class="card-body">
                          <p class="testimonial-text">I have transacted with Durrafx and can attest they offer the best rates when in comes to forex</p>
                          <div class="testimonial-author">Ngozi</div>
                          <div class="occupation">Trader, Yellow card</div>
                      </div>
                      <div class="gradient-floor red-to-blue"></div>
                  </div>
                
                  
                  <div class="card">
                      <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                      <div class="card-body">
                          <p class="testimonial-text">I have transacted with Durrafx and can attest they offer the best rates when in comes to forex</p>
                          <div class="testimonial-author">Ngozi</div>
                          <div class="occupation">Trader, Yellow card</div>
                      </div>
                      <div class="gradient-floor red-to-blue"></div>
                  </div>
                
                  
                  <div class="card">
                      <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                      <div class="card-body">
                          <p class="testimonial-text">I have transacted with Durrafx and can attest they offer the best rates when in comes to forex</p>
                          <div class="testimonial-author">Ngozi</div>
                          <div class="occupation">Trader, Yellow card</div>
                      </div>
                      <div class="gradient-floor purple-to-green"></div>
                  </div>
                
              </div> 
          </div>
      </div> 
    </div> 
    <div class="slider-1">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <h4>Trusted by over <span class="blue">1000</span> customers worldwide</h4>
                  <hr class="section-divider" />
               
                  <!-- <div class="slider-container">
                      <div class="swiper-container image-slider">
                          <div class="swiper-wrapper">
                              <div class="swiper-slide">
                                  <img class="img-fluid" src="assets/images/customer-logo-1.png" alt="alternative" />
                              </div>
                              <div class="swiper-slide">
                                  <img class="img-fluid" src="assets/images/customer-logo-2.png" alt="alternative" />
                              </div>
                              <div class="swiper-slide">
                                  <img class="img-fluid" src="assets/images/customer-logo-3.png" alt="alternative" />
                              </div>
                              <div class="swiper-slide">
                                  <img class="img-fluid" src="assets/images/customer-logo-4.png" alt="alternative" />
                              </div>
                              <div class="swiper-slide">
                                  <img class="img-fluid" src="assets/images/customer-logo-5.png" alt="alternative" />
                              </div>
                              <div class="swiper-slide">
                                  <img class="img-fluid" src="assets/images/customer-logo-6.png" alt="alternative" />
                              </div>
                          </div>
                      </div> 
                  </div>  -->
                 
              </div> 
          </div>
      </div> 
    </div> 
    
    <div class="basic-3">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="text-container">
                      <h2>Find the partiner in your forex needs</h2>
                      <p class="p-large">We help you trade assets and convert your crypto to your loacl currency through the wallet</p>
                      <a class="btn-solid-lg" href="#contact">Get in touch</a>
                  </div> 
              </div> 
          </div>
      </div> 
    </div> 
    <div id="contact" class="form-1">
      <div class="container">
          <div class="row">
              <div class="col-lg-6">
                  <div class="text-container">
                      <div class="section-title">GET QUOTE</div>
                      <h2>Submit the form for quote</h2>
                      <p></p>
                      <!-- <ul class="list-unstyled li-space-lg">
                          <li class="d-flex">
                              <i class="fas fa-square"></i>
                              <div class="flex-grow-1">Vel maximus nunc aliquam ut. Donec semper, magna a pulvinar</div>
                          </li>
                          <li class="d-flex">
                              <i class="fas fa-square"></i>
                              <div class="flex-grow-1">Suscipit sit amet quis lorem. Sed risus ipsum, egestas mare</div>
                          </li>
                          <li class="d-flex">
                              <i class="fas fa-square"></i>
                              <div class="flex-grow-1">Sem pulvinar suscipit sit amet quis lorem. Sed risus</div>
                          </li>
                      </ul> -->
                  </div> 
              </div> 
              <div class="col-lg-6">
                 
                  <form>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="Name" required />
                      </div>
                      <div class="form-group">
                          <input type="email" class="form-control-input" placeholder="Email" required />
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="Industry" required />
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control-input" placeholder="Your product" required />
                      </div>
                      <div class="form-group">
                          <button type="submit" class="form-control-submit-button">Submit</button>
                      </div>
                  </form>
                
              </div> 
          </div>
      </div> 
    </div>
    <footer-bar></footer-bar>
    
    <button id="myBtn">
      <img src="assets/images/up-arrow.png" alt="alternative" />
    </button>
    </div>
    </template>
    
    
    
    <style>
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 0px;
    }
    .main-content {
  padding-top: 0px;
  margin-top: 0px;
  z-index: 1;
  position: relative;
}
    </style>
    