<template>
    <div>
      <base-navigation></base-navigation>
<base-card>
<!-- clients {{ clients }} -->
        <form @submit.prevent="newTrade">
  <div class="form-group">
    <div class="row">
      <div class="col">
        <div>
          <label for="name">Base currency:</label>
          <select v-model="formData.base_currency" id="status" required>
            <option disabled value=""> Select</option>
            <option>USDT</option>
            <option>USD</option>

          </select>        
        </div> 
        <div>
          <label for="name">Counter currency:</label>
          <select v-model="formData.counter_currency" id="status" required>
            <option disabled value=""> Select one</option>
            <option>KES</option>
            <option>TZS</option>
            <option>ETB</option>
            <option>AOA</option>
            <option>ZMW</option>
            <option>EGP</option>
            <option>RWF</option>
            <option>MWK</option>

          </select>        
        </div> 

        <div>
          <label for="name">Select client:</label>

          <select  v-model="formData.client">
            <option disabled value="">
              Select client
            </option>
             <option   v-for="client in clients" :key="client.id">
        {{client.Business_name }}
    </option>
          </select>
          <!-- <label for="name">Select client:</label>
          <select v-model="formData.client" id="client" required>
            <option disabled value="">Please select one</option>
            <option v-for="company in companies" :key="company" :value="company">{{ company }}</option>
          </select>        -->
         </div>
         <div>
          <label for="name">Select liquidity provider:</label>
          <select v-model="formData.liquidity_provider" id="liquidity" required>
            <option disabled value="">Please select one</option>
            <option   v-for="client in clients" :key="client.id">
        {{client.Business_name }}
    </option>          </select>       
         </div>

         <div>
          <label for="name">Volume in Base currency(USDT)</label>
          <input type="text" v-model="formData.base_volume" id="name"  />
        </div>

    
        
       

      </div>
      <div class="col">
        <div>
          <label for="name">Client Rate:</label>
          <input type="text" v-model="formData.c_rate" id="c_rate" required />
        </div>

        <div>
          <label for="name">Liquidity Rate:</label>
          <input type="text" v-model="formData.l_rate" id="l_rate" required />
        </div>

       
        
       
        <div>
          <label for="name">Volume in local currency</label>
          <input type="text" v-model="formData.local_volume" id="name"  />
        </div>
        <div>
          <label for="name">Notes:</label>
          <input type="text" v-model="formData.notes" id="name" required />
        </div>
        <div>
          
          <label for="registrationDate">Trade Date:</label>
          <input type="date" v-model="formData.trade_date" id="registrationDate" required />
        </div>

</div>

    </div>
   
        
  </div>
  
 
  <button type="submit" class="btn btn-primary">Submit</button>
</form>

</base-card>

   
    <div class="client-registration-form">
   
    </div>
</div>
  </template>
  
  <script>
import axios from 'axios';
  
  export default {
    data() {
      return {
        formData: {
        
          trade_date: '',
          client: '',
          liquidity_provider:'',
          c_rate:'',
          l_rate:'',
          local_volume:'',
          base_volume:'',
          base_currency:'',
          counter_currency:'',
          notes:''
        },
        // countries: ['USA', 'Canada', 'UK', 'Australia', 'India', 'Nigeria', 'South Africa'],
        // companies:['Yellow card', 'technical']
      };
    },
    computed: {
        clients() {
            return  this.$store.getters.clients

            
        }

    },
    methods: {
      
    async newTrade() {
      const formIsValid =
        this.formData.c_rate;
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/durra/new/trade",
          this.formData
        );
        console.log(response);

        await this.$router.replace("/clients");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.c_rate) {
          this.errors.push("Enter Business Name ");
        }
        

        console.log("form is invalid");
      }
    },
      submitForm() {
        // Handle form submission, e.g., send data to an API
        console.log('Form data:', this.form);
        // Reset the form
        this.form.name = '';
        this.form.registrationDate = '';
        this.form.country = '';
      }
    },
    created() {
    this.$store.dispatch('LoadClients');  
    
    
  },
  };
  </script>
  
  <style scoped>
  
  .client-registration-form {
    max-width: 400px;
    margin: auto;
  }
  form div {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input, select, button {
    width: 100%;
    padding: 0.5rem;
  }
  </style>
  