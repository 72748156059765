<template>
    <div>
      <base-navigation></base-navigation>
<base-card>
        <form @submit.prevent="registerClient">
  <div class="form-group">
    <div class="row">
      <div class="col">
        <div>
          <label for="name">Business Identification Number:</label>
          <input type="text" v-model="formData.Business_id" id="name" required />
        </div>
        <div>
          <label for="name">Name:</label>
          <input type="text" v-model="formData.Business_name" id="name" required />
        </div>
        <div>
          <label for="registrationDate">Registration Date:</label>
          <input type="date" v-model="formData.Registration_date" id="registrationDate" required />
        </div>
        <div>
          <label for="name">Country:</label>
          <input type="text" v-model="formData.country" id="country" required />
        </div>
      

      </div>
      <div class="col">
        <div>
          <label for="status">Status:</label>
          <select v-model="formData.status" id="status" required>
            <option disabled value="">Please select status</option>
            <option>Active</option>
            <option>Pending KCV</option>
            <option>Deactivated</option>

          </select>
          
        </div>
        <div>
          <label for="status">Client type:</label>
          <select v-model="formData.client_type" id="client_type" required>
            <option disabled value="">Please select type</option>
            <option>Supplier</option>
            <option>Liquidity provider</option>

          </select>
          
        </div>
        <div>
          <label for="name">Address:</label>
          <input type="text" v-model="formData.address" id="name" required />
        </div>
        <div>
          <label for="name">key individual name, position and contact:</label>
          <input type="text" v-model="formData.key_person" id="name" required />
        </div>

</div>

    </div>
   
        
  </div>
  
 
  <button type="submit" class="btn btn-primary">Submit</button>
</form>

</base-card>

   
    <div class="client-registration-form">
      <!-- <form @submit.prevent="submitForm">
        <div>
          <label for="name">Name:</label>
          <input type="text" v-model="form.name" id="name" required />
        </div>
      
        
      </form> -->
    </div>
</div>
  </template>
  
  <script>
import axios from 'axios';
  
  export default {
    data() {
      return {
        formData: {
          Business_name: '',
          Business_id: '',
          Registration_date: '',
          country: '',
          status:'',
          client_type:'',
          address:''
        },
        countries: ['USA', 'Canada', 'UK', 'Australia', 'India', 'Nigeria', 'South Africa']
      };
    },
    methods: {
      async registerClient() {
      const formIsValid =
        this.formData.Business_name &&
        this.formData.Business_id &&
        this.formData.key_person;
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/durra/client/registration",
          this.formData
        );
        console.log(response);

        await this.$router.replace("/clients");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.Business_name) {
          this.errors.push("Enter Business Name ");
        }
        if (!this.formData.Business_id) {
          this.errors.push("Enter business registration Number");
        }
        if (!this.formData.registration_date) {
          this.errors.push("Enter registration date");
        }
        

        console.log("form is invalid");
      }
    },
      submitForm() {
        // Handle form submission, e.g., send data to an API
        console.log('Form data:', this.form);
        // Reset the form
        this.form.name = '';
        this.form.registrationDate = '';
        this.form.country = '';
      }
    }
  };
  </script>
  
  <style scoped>
  
  .client-registration-form {
    max-width: 400px;
    margin: auto;
  }
  form div {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input, select, button {
    width: 100%;
    padding: 0.5rem;
  }
  </style>
  