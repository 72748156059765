<template>
    <div>
     <base-navigation></base-navigation>
      
     <base-card> 
    <div class="form-outline">
  <input type="search" id="form1" class="form-control" v-model="search" placeholder="client name"  aria-label="Search"  />
</div>
</base-card>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <th><small> Client name</small></th>
                <th><small>curency</small></th>
                <th><small>liquidity provider</small></th>
                <th><small> Client Rate </small></th>
                <th><small>Counter Volume</small></th>
                <th><small>Liquidity rate</small></th>
                <th><small>Trade Date</small></th>
                <th><small>Profit ($)</small></th>
                <!-- <th><small>Invoice</small></th> -->
               
              
            </tr>
            </thead> 
            <tbody>
                <!-- <tr v-for="trade in filteredTrades.slice(0,4)" :key="trade.id"> -->
                  <tr v-for="trade in trades" :key="trade.id">

          <td> <router-link
            :to="`/client/name/${trade.client}`"
          >
            {{ trade.client }}
          </router-link> </td> 
          
     
                   
                  
                    <td><small>{{ trade.base_currency  }}/{{ trade.counter_currency  }}</small></td>
                    <td><small>{{ trade.liquidity_provider }}</small></td>
                    <td><small>{{ trade.c_rate }}</small></td>
                    <td><small>{{ trade.base_volume }}</small></td>
                      <td><small>{{ trade.l_rate }}</small></td>
                      <td><small>{{ trade.trade_date.split("T")[0] }}</small></td>
                      <td><small>{{ trade.profit }}</small></td>

                   
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

</div>
</template>
<script>


export default {
    data() {
    return {
      search: "",
    };
  },
    computed: {
   
    trades() {
            return  this.$store.getters.trades //.filter(process=>process.processed =='0')

            
        },
        filteredTrades() {
        return this.trades.filter((trade)=>trade.client.toLowerCase().includes(this.search.toLowerCase())).slice(0,5)
        
       
    },
  

    },
    actions: {
     
    
    


    },
     created() {
    this.$store.dispatch('LoadTrades');  
    
    
  },

}

</script>