<template>
  <header class="navbar">
    <nav class="nav-container">
      <div class="logo">
        <a href="#">
          <img src="/assets/images/logo.png" alt="Logo" />
        </a>
      </div>
            <!-- <ul class="navbar-nav ms-auto navbar-nav-scroll">
              
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#header">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#services">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#details">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#leadership">Leadership</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a class="dropdown-item" href="#">Exchange currency</a></li>
                        <li><div class="dropdown-divider"></div></li>
                        <li><a class="dropdown-item" href="#">Collections and Payments </a></li>
                        <li><div class="dropdown-divider"></div></li>
                        <li><a class="dropdown-item" href="#">Crpto</a></li>
                    </ul>
                </li>
            </ul>
            <span class="nav-item">
                <a class="btn-solid-sm" href="/login">Admin</a>
            </span> -->
        <!-- </div>  -->
      <ul class="nav-links  ms-auto ">
        
        <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#header">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#services">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#details">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#leadership">Leadership</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#partiners">Partiners</a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a class="dropdown-item" href="#">Exchange currency</a></li>
                        <li><div class="dropdown-divider"></div></li>
                        <li><a class="dropdown-item" href="#">Collections and Payments </a></li>
                        <li><div class="dropdown-divider"></div></li>
                        <li><a class="dropdown-item" href="#">Crpto</a></li>
                    </ul>
                </li> -->
      </ul>
      <span class="nav-item">
                <a class="btn-solid-sm" href="/login">Admin</a>
            </span> 
      <!-- <div class="login-button">
        <a href="#">Login</a>
      </div> -->
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>
/* General Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height:80px;
  
  background-color: transparent;
  padding: 15px 50px;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar:hover {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Navigation Container Styling */
.nav-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo Styling */
.logo img {
  height: 80px; /* Adjust the size of your logo */
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Navigation Links Styling */
.nav-links {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 30px;
  list-style-type: none; /* Remove bullet points (dots) */
  padding: 0; /* Remove default padding */
}

.nav-links li {
  margin-left: 30px;
}

.nav-links li:first-child {
  margin-left: 0;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.nav-links a::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: translateX(-50%) scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}

.nav-links a:hover::before {
  transform: translateX(-50%) scaleX(1);
  transform-origin: left;
}

.navbar:hover .nav-links a {
  color: black;
}

.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Login Button Styling */
.login-button a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 25px;
  border: 2px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar:hover .login-button a {
  color: black;
  border-color: black;
}

.login-button a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .nav-links {
    display: none; /* Hide links on smaller screens, implement a hamburger menu for mobile view */
  }

  .login-button a {
    font-size: 16px;
    padding: 8px 15px;
  }

  .logo img {
    height: 40px;
  }
}
</style>
