<template>
    <div>
      <base-navigation></base-navigation>
    
  <base-card>
  
    <h2>Login</h2>
    <form @submit.prevent="loginUser">

    
      <div  >
        <label for="email">Email </label>
        <input class="form-control" type="text" id="email" v-model="formData.email" />
      </div>
       <div>
        <label for="password">Password </label>
        <input class="form-control" type="password" id="password" v-model="formData.password" />
      </div>
       
      
      <base-button>Login</base-button>
      <br>
      <muted> <router-link to="/">Home page</router-link> </muted> 
    </form>
    </base-card>
  </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
      
   
    data() {
      return {
          formData: {
              email: '',
              password: '',
              
  
          },
        
      }
    },
    methods: {
      async loginUser(){
        const response = await axios.post("https://api.roberms.com/delivery/login", this.formData)
         
        let user = response.data.user
        console.log(user)
        
        localStorage.setItem("user", JSON.stringify(user))
        localStorage.setItem('token', response.data.token)
        // this.$store.dispatch('addToken', response.data.token)
        // console.log(localStorage.user) 
  
        this.$router.push('/clients')
        // const user = await localStorage.getItem(user)
        // console.log(user)
        
          // axios.post("http://localhost:5000/login", this.formData)
          // .then(response=>console.log(response))
          // .catch(error=>console.log(error))
          // this.$router.replace('/log');
  
      
  
      },
    },
    
  };
  </script>
  
  <style scoped>
/* Background Styling */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  font-family: 'Arial', sans-serif;
}

/* Login Box Styling */
.login-box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
}

/* Header Styling */
.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  height: 60px;
  margin-bottom: 10px;
}

h2 {
  margin: 0;
  color: #333;
  font-weight: 600;
}

/* Input Group Styling */
.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  color: #666;
  margin-bottom: 5px;
  font-weight: 500;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  color: #333;
}

.input-group input:focus {
  border-color: #6a11cb;
  outline: none;
  box-shadow: 0 0 5px rgba(106, 17, 203, 0.3);
}

/* Button Styling */
.login-button {
  width: 100%;
  padding: 12px;
  background-color: #6a11cb;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #5a0fbf;
}

/* Footer Styling */
.login-footer {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.login-footer a {
  color: #6a11cb;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}
</style>
  
  
      
  
  
  <script>
  
  
  </script>