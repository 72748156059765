<template>
    <div class="carousel">
      <div 
        v-for="(slide, index) in slides" 
        :key="index" 
        class="carousel-slide" 
        :class="{ active: currentIndex === index }" 
        :style="{ backgroundImage: `url(${slide.image})` }"
      >
        <div class="carousel-text">
          <h2>{{ slide.text }}</h2>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentIndex: 0,
        slides: [
          {
            image: 'assets/images/durra30.jpg',
            text: ''
          },
          {
            image: 'assets/images/durra10.jpg',
            text: ''
          },
          {
            image: 'assets/images/durra30.jpg',
            text: ''
          },
          {
            image: 'assets/images/durra10.jpg',
            text: ''
          },
        ],
      };
    },
    mounted() {
      this.startCarousel();
    },
    methods: {
      startCarousel() {
        setInterval(() => {
          this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        }, 60000); // 10 seconds interval now 6 mins
      },
    },
  };
  </script>
  
  <style scoped>
  .carousel {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .carousel-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .carousel-slide.active {
    opacity: 1;
  }
  
  .carousel-text {
    position: absolute;
    top: 250px; /* Adjust this value to move the text down */
    left: 20px;
    color: rgb(241, 243, 242);
    text-align: left;
    font-size: 2em;
    text-shadow: 2px 2px 8px rgba(46, 245, 15, 0.713);
  }
  </style>
  